@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #1c1d21;
  color: #eee;
  margin:0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}
h1{
  font-size:2.986rem;
  color: var(--accent-color-1);
}
h2{
  font-size:2.488rem;
  color: var(--accent-color-1);
}
h3{
  font-size:2.074rem;
  color: var(--accent-color-1);
}
h4{
  font-size:1.728rem;
  color: var(--accent-color-1);
}
h5{
  font-size:1.44rem;
  color: var(--accent-color-1);
}
h6{
  font-size: 1.2rem;
  color: var(--accent-color-1);
}
p{
  font-size: 1rem;
}
small{
  font-size: 0.833rem;
  color: var(--accent-color-1);
}
.newsflash{
  color: var(--accent-color-1);
  font-style: italic;
}
form h4{
  margin: 16px 0 0 0;
}
form h5{
  margin: 4px 0;
}
form h6{
  margin: 2px 0;
}

/* Accent Colors */
:root {
  --accent-color-1: #A44333;
  --accent-color-2: #6ccff6;
}